import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useResource } from '@tg/core/hooks';
import {
  Button,
  Form,
  Heading,
  ButtonBar,
  NavigationConfirm,
} from '@tg/core/components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validationSchema from './validation';

const CreateContract = ({ onSuccess }) => {
  const { t } = useTranslation(['forms', 'contract', 'employees']);

  const { errors, postResource, isFetching } = useResource({
    url: `employers/contracts`,
  });

  const { handleSubmit, getFieldProps, watch, control, formState } =
    Form.useForm({
      validationSchema,
    });

  /*
  Track if we should show the 'You have unsaved changes' promt
  */
  const [allowNavigateAway, setAllowNavigateAway] = useState(true);
  useEffect(() => {
    setAllowNavigateAway(!formState.isDirty);
  }, [formState.isDirty]);

  const history = useHistory();
  const goToEmployee = employeeId => {
    history.push({ pathname: `/employees/${employeeId}` });
  };

  const onSubmit = formData => {
    postResource({
      formData,
      onSuccess: response => {
        onSuccess();
        setAllowNavigateAway(true);
        goToEmployee(response.data.id);
      },
    });
  };

  // TODO: create a better selector for this // as 'fixed term' may not reliably be
  // in this array position
  const contractTypesFixedTerm = useSelector(
    state => state.collections.contract_types.allIds[1],
  );
  const watchContractType = watch(`contract.contract_type_id`, null);

  return (
    <>
      <NavigationConfirm
        navigate={path => history.push(path)}
        shouldBlockNavigation={!allowNavigateAway}
      />
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <Heading element='h1' level='h2'>
          {t('employees:add.title')}
        </Heading>
        <div className='space-y-8'>
          <Form.Sets.Employee
            heading={t('contract:employee_details')}
            getFieldProps={getFieldProps}
            control={control}
            mode='add'
          />

          <fieldset>
            <Heading element='legend' level='h4'>
              {t('contract:contract_details')}
            </Heading>
            <Form.HiddenField
              {...getFieldProps({ id: 'contract', name: 'fixed_term' })}
              defaultValue={
                watchContractType &&
                watchContractType === contractTypesFixedTerm
              }
            />

            <div className='grid grid-cols-2 gap-4'>
              <Form.TextField
                {...getFieldProps({ id: 'contract', name: 'job_title' })}
                required
              />
              <Form.CollectionField
                {...getFieldProps({
                  id: 'contract',
                  name: 'attendance_type_id',
                })}
                resource='attendance_types'
                control={control}
                required
                useName
              />

              <div className='grid grid-cols-3 gap-4 col-span-2'>
                <Form.CollectionField
                  {...getFieldProps({
                    id: 'contract',
                    name: 'contract_type_id',
                  })}
                  resource='contract_types'
                  control={control}
                  required
                  search
                  useName
                />

                <Form.DateField
                  {...getFieldProps({ id: 'contract', name: 'start_date' })}
                  required
                />

                {watchContractType &&
                  watchContractType === contractTypesFixedTerm && (
                    <Form.DateField
                      {...getFieldProps({ id: 'contract', name: 'end_date' })}
                    />
                  )}
              </div>

              <Form.CollectionField
                {...getFieldProps({ id: 'contract', name: 'currency_id' })}
                resource='currencies'
                control={control}
                required
                status
              />
              <Form.TextField
                {...getFieldProps({ id: 'contract', name: 'gross_salary' })}
                required
              />
              <div className='col-span-2 pb-4'>
                <Form.CheckboxField
                  {...getFieldProps({
                    name: 'contractor',
                    id: 'contract',
                  })}
                  control={control}
                />
              </div>
            </div>
          </fieldset>
          <ButtonBar type='border'>
            <Button type='submit' loading={isFetching}>
              {t('forms:buttons.save')}
            </Button>
          </ButtonBar>
        </div>
      </Form>
    </>
  );
};

CreateContract.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default CreateContract;
